.toast {
  .toast-header {
    justify-content: space-between;
    align-items: start;
    word-break: break-word;

    small {
      white-space: nowrap;
    }

    .btn-close {
      @extend .btn-close-white;
    }
  }
}
