.view\:login {
  display: flex;
  
  .login-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  .login-component {
    padding: 3rem;

    @include media-breakpoint-up('md') {
      padding: 5rem 6rem;
    }
  }
}