@use '../modules' as *;

.file-uploader-wrapper {
  table {
    overflow: hidden;
    border-radius: $border-radius;
  }

  .file-uploader-files {
    width: 100%;
  }
  .file-uploader-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    min-height: 200px;
    border: 1px dashed $gray-600;
    border-radius: $border-radius;
    transition: $transition-base;

    .instructions {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: $transition-base;
      padding: 3rem;

      input {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
      }
    }

    &.active {
      background-color: tint-color($secondary, 90%);

      .instructions {
        pointer-events: none;
      }
    }
  }
}
