.navbar {
  .auto-suggest-input {
    width: 400px;
  }

  .navbar-nav .nav-link {
    position: relative;

    &::after {
      --active-bar-height: 3px;
      content: '';
      position: absolute;
      bottom: calc(-1 * $navbar-padding-y - $nav-link-padding-y + var(--active-bar-height));
      left: 0;
      height: 3px;
      width: 100%;
      background-color: $primary;
      opacity: 0;
      transition: $transition-base;
    }

    &.active {
      &::after {
        opacity: 1;
      }
    }
  }

  .navbar-brand {
    .st0,
    .st1 {
      fill: white !important;
    }

    height: $navbar-brand-height;

    svg {
      height: 100%;
    }
  }
}
