html,
body {
  min-height: 100%;
  height: 100%;
}

#root,
.page-wrapper {
  height: 100%;
}

.view-wrapper {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 3rem;
  width: 100%;
  height: calc(100% - 66px);
  padding-bottom: 1em;
  overflow: hidden;

  &:has(+ #active-patients) {
    padding-bottom: 3rem;
  }

  > .sidebar {
    padding: 3rem 3rem;
    overflow: hidden auto;

    h5 {
      white-space: nowrap;
    }
  }

  > .view-content {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    gap: 5rem;
  }
}

#active-patients {
  // Highest possible z-index
  z-index: $zindex-toast;

  .btn {
    svg {
      opacity: 0;
      margin-right: -1em;
      transition: $transition-base;
    }

    // Just :hover - :focus and :active have undesirable results
    &:hover {
      svg {
        opacity: 1;
        margin-right: 0;
      }
    }
  }
}

a[href] {
  text-underline-offset: 2px;
}

.rmdp-wrapper {
  border: 1px solid $list-group-border-color;
}

[class*='bs-popover'] {
  position: fixed !important;
}

.app-error-container {
  display: flex;
  width: 100%;
  min-height: 100%;
  padding: 5rem 0;
  justify-content: center;
  align-items: center;
  overflow: hidden scroll;

  .app-error-message {
    width: min(1000px, 90%); // clamp to max 1000px
    padding: 3rem;
    border: 1px solid $colourBurningOrange;
    border-radius: $border-radius;
    word-break: break-word;
  }
}

.availability-table {
  border-radius: $border-radius;
  overflow: hidden;
}
