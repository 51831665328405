@use '../modules' as *;

.loading-cover {
  position: absolute;
  inset: 0px;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  z-index: 1010;
  font-size: $font-size-lg;
  background-color: rgba(255, 255, 255, 0.85);
}
