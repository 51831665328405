@use '../modules.scss' as *;

.pre-vaccination-checklist {
  .field-check-row {
    &:not(.has-content) .action-button {
      visibility: hidden;
    }
    &:hover {
      .action-button {
        visibility: visible;
      }
    }
  }
}
