@use '../modules.scss' as *;

.fc-event {
  @each $type, $colour in $appointment-type-colours {
    &.type\:#{$type} {
      --type-colour: var(--bs-#{$type});
    }
  }
  @each $status, $colour in $appointment-status-colours {
    &.status\:#{$status} {
      --status-colour: var(--bs-#{$status});
    }
  }

  // Border around events
  --fc-event-border-color: var(--type-colour);

  // Box shadow around events
  --fc-page-bg-color: #ffffff00;

  // Text color
  --fc-event-text-color: #333333;

  user-select: none;
  cursor: pointer;

  background-color: white !important;
  border: none !important;

  border-radius: 3px;

  overflow: hidden;

  // border-width: 1px;

  .event-block {
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;
    position: relative;
    height: 100%;
    // border: 1px solid var(--type-colour);
    border-radius: 3px;
    overflow: hidden;

    .event-type {
      min-width: 0.5em;
      min-height: 100%;
      display: inline-block;
      background: var(--type-colour);
      border-right: 1px solid var(--fc-event-border-color);
      // opacity: 0.4;
    }

    .event-block-inner {
      --padding: 5px;

      position: relative;
      width: 100%;
      padding: var(--padding) var(--padding) var(--padding) 0px;
      // background: var(--type-colour);

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(-1 * var(--padding));
        width: calc(100% + var(--padding));
        height: 100%;
        background: var(--type-colour);
        opacity: 0.4;
        z-index: -1;
      }
    }
  }
}
