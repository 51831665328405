@use '../modules' as *;

.sidebar-panel {
  h2,
  h3,
  h4,
  h5 {
    white-space: nowrap;
  }
  background-color: white;
  padding: 2rem 2rem 2rem 2rem;
}
