@use '../modules' as *;

.icon-sidebar {
  position: relative;
  z-index: calc($zindex-offcanvas-backdrop - 1);

  .sidebar-panel {
    height: 100%;
    overflow-y: auto;
  }

  .icon-sidebar-wrapper {
    height: 100%;
    background-color: white;
  }
}
