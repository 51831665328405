@use '../modules' as *;

// .btn selector is needed to overwrite bootstrap defaults
.patient-tag,
.btn.patient-tag {
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.4em;
  border-radius: 20px;
  padding: 0.25em 0.7em 0.25em 0.5em;

  svg {
    height: 100%;
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 0.1em;
    background: white;
    border-radius: 50%;
    fill: $secondary;
  }

  button {
    display: flex;
    place-content: center;
    border: none;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    padding: 0;
    background-color: rgba($white, 0.75);
    transition: $transition-base;

    &:hover {
      background-color: $white;
    }
  }
}
