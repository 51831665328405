.btn {
  white-space: nowrap;
  height: fit-content;

  &.loading {
    pointer-events: none;
  }
}

.btn-primary {
  --bs-btn-color          : white;
  --bs-btn-hover-color    : white;
  --bs-btn-active-color   : white;
  --bs-btn-disabled-color : white;
}