// ... Colours

$colourParadiso: #317784; // ... Main green colour
$colourPersianGreen: #009592; // ... Green hover colour

$colourBurningOrange: #fc6c2e; // ... Call to action

$colourDenim: #1876d2; // ... Secondary call to action

$colourSeance: #9c27b0; // ... Tags

$appointment-type-colours: (
  yellowFever: hsl(55, 71%, 51%),
  bloodTest: hsl(39, 48%, 43%),
  general: hsl(190, 82%, 65%),
  nationwide: hsl(18, 97%, 58%),
  // Child appointments
  rtnPrvpt: hsl(333, 77%, 69%),
  admin: hsl(122, 82%, 65%),
);

$appointment-status-colours: (
  active: hsl(122, 83%, 49%),
  didNotAttend: hsl(0, 46%, 35%),
  toBeConfirmed: hsl(0, 100%, 25%),
  cancelled: hsl(0, 46%, 35%),
  reserved: hsl(0, 46%, 35%),
);

$appointment-colours: map-merge($appointment-type-colours, $appointment-status-colours);
