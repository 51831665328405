@use '../modules' as *;

.table-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0.3rem 0.3rem 0;
  margin: -0.3rem -0.3rem 0;

  .table-responsive {
    overflow: auto;
  }

  table {
    // border: 1px solid $table-border-color;
    border-radius: $border-radius;
    overflow: hidden;

    &.notes-table {
      .form-select {
        width: fit-content;
      }
    }

    thead {
      th {
        white-space: nowrap;

        span {
          &.user-select-none {
            // Sortable headers
            position: relative;
            cursor: pointer;

            svg {
              position: absolute;
              top: 50%;
              left: 100%;
              transform: translateY(-50%);
              width: 2em;
              height: 2em;
            }
          }
        }
      }
    }

    tbody {
    }
  }
}
