@use '../modules' as *;

.auto-suggest-input {
  // position: relative;

  &.show {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .results-container {
    position: absolute;
    width: 100%;

    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 10;
  }
}
