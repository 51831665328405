.view\:index,
.view\:testing {
  .view-content {
    > .calendar-widget {
      position: relative;
      width: 100%;
      padding: 2rem 2rem 0 0;
      flex: 1 1;

      .fc-scrollgrid {
        border-radius: $border-radius;
        overflow: hidden;
      }

      .fc {
        --fc-non-business-color: #f4f4f4;
        --fc-today-bg-color: #d9faff84;

        .fc-header-toolbar {
          margin-bottom: 2rem;

          .btn-group button {
            text-transform: uppercase;
          }

          .fc-toolbar-chunk:last-child {
            display: flex;
            flex-wrap: nowrap;
          }
        }

        .fc-day.fc-daygrid-day.fc-resource.room-unavailable {
          // background-color: #f4f4f4;

          .fc-daygrid-day-events {
            z-index: -1;
          }
        }

        .fc-timegrid-slots {
          z-index: -1;
        }

        .fc-timegrid-col.fc-resource.room-unavailable {
          background-color: #f4f4f4;

          .fc-timegrid-col-events {
            z-index: -1;
          }
        }

        .fc-col-header-cell.fc-resource {
          padding: 0 !important;

          .fc-col-header-cell-cushion {
            padding: 0;
          }
        }

        thead {
          user-select: none;

          th {
            background-color: var(--bs-secondary);
            color: white;
          }
        }

        .fc-col-header {
          th:not([aria-hidden]) {
            padding: $table-cell-padding-y $table-cell-padding-x;

            a {
              padding: 0;
            }
          }
        }

        // Start Views

        .fc-dayGridMonth-view {
          .fc-event.staff-event {
            display: none;
          }

          .fc-daygrid-event-harness {
            margin-bottom: 3px;
          }
        }

        .fc-timeGridDay-view {
          thead {
            display: none;
          }
        }

        .fc-resourceTimeGridWeek-view,
        .fc-resourceTimeGridDay-view {
          .fc-daygrid-day-frame {
            width: 200%;
          }
        }

        // End views

        .fc-day-other {
          opacity: 0.4;
        }
      }
    }
  }
}
